import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// import { PipesModule } from './pipes/pipes.module';
import { StoreModule } from './store/store.module';
import { ApiModule } from './modules/api/api.module';
// import { DirectivesModule } from './directives/directives.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    // DirectivesModule,
    ApiModule,
    // PipesModule,
    StoreModule, 
  ],
  providers: [AppComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}
