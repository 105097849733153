import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
// import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';

import {
  DEVTOOLS_REDUX_CONFIG,
  // LOGGER_CONFIG,
  OPTIONS_CONFIG,
  STATES_MODULES,
  STORAGE_CONFIG,
} from './store.config';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NgxsModule.forRoot(STATES_MODULES, OPTIONS_CONFIG),
    NgxsStoragePluginModule.forRoot(STORAGE_CONFIG),
    NgxsReduxDevtoolsPluginModule.forRoot(DEVTOOLS_REDUX_CONFIG),
    // NgxsLoggerPluginModule.forRoot(LOGGER_CONFIG),
  ],
})
export class StoreModule { }